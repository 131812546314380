import React from "react"
import styled from '@emotion/styled'
import {
  ActivityBox,
  ToSeeBox,
  DiscussBox,
  CopyrightBox,
  QuoteBox,
} from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import bag from './bag.svg'
import candle from './candle.svg'
import worksheets from "../worksheets.svg"
import { withPrefix } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import candles from './candles.mp4'

const QuotesContainer = styled.div`
  column-gap: 1em;
  columns: 20em auto ;
  
  & > * {
    break-inside: avoid;
  }
`;

const PilgrimageToday = () => (
  <Layout title="Pilgrimage Today">
    <SmallSectionHeader
      color={theme.colors.red.dark}
      image={headerImage}
      to="/pilgrimage"
    >
      Pilgrimage
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/pilgrimage', name: 'Pilgrimage' },
      { name: 'Pilrgimage Today' }
    ]} />
    <Page>
      <h1>Pilgrimage today</h1>
      <p className="page-left">
        People have always wanted to see and experience the places they read about in their holy books.
      </p>
      <Figure
        caption="Pilgrim’s Pass and other printed pilgrimage materials are distributed to pilgrims who come to the Cathedral."
        className="page-right row-4"
      >
        <StaticImage
          src="./passes.jpg"
          alt="Pilgrims Passes"
        />
      </Figure>
      <p className="page-left">
        Canterbury Cathedral has been a major pilgrimage destination for many centuries and it was the murder of Archbishop Thomas Becket in 1170 and his subsequent canonisation in 1173 that made Canterbury Cathedral the third most important site of Christian pilgrimage in the world, after Jerusalem and Rome. Pilgrims continued to visit the Cathedral in great numbers until the reformation and the destruction of the shrine of St Thomas in 1538.
      </p>
      <p className="page-left">
        In more recent times, pilgrimage has become increasing popular and today Canterbury Cathedral welcomes thousands of people every year both setting out on and finishing their journey of pilgrimage.
      </p>
      <p className="page-left">
        Pilgrimage is an outward expression of faith and many pilgrims say it helps them to feel closer to God. Of course, many people also enjoy getting to experience new people and places.
      </p>
      <video src={candles} controls />

      <DiscussBox>What does pilgrimage mean to a believer?</DiscussBox>

      <p className="page-left">
        Pilgrimages continue to be undertaken all across the world. Hinduism and Islam suggest that every believer should try to go on a pilgrimage once in their lifetime. Christians choose to undertake a pilgrimage to develop spiritually and feel closer to God, this is something that is shared across the Christian denominations.
      </p>
      <DiscussBox>
        How does a pilgrim feel at different stages of their journey?
      </DiscussBox>

      <p className="page-left">
        A recent project undertaken by a team at the University of York has been looking at the reasons why so many pilgrims and other visitors come to places like Canterbury Cathedral. The team of researchers spoke to people visiting a variety of Cathedrals. They asked questions about the reasons for their visit, what they did during their time in the Cathedral and also what longer-term impact their visit might have on them.
      </p>
      <p className="page-left">
        You can find out more here:<br />
        <a href="https://www.york.ac.uk/projects/pilgrimage">www.york.ac.uk/projects/pilgrimage</a><br />
        <a href="https://www.thebecketstory.org.uk">www.thebecketstory.org.uk</a><br />
        <a href="https://becket2020.com">becket2020.com</a>
      </p>

      <DiscussBox>
        What different things do these people say they did at the Cathedral?
      </DiscussBox>
      <p>
        These are some of the replies and responses that were given. You can see that some people have called themselves visitors and some people have called themselves pilgrims.
      </p>
      <QuotesContainer>
        <QuoteBox cite="Pilgrim">
          [I] was always aware of his [Becket’s] courage and faith but the pilgrimage brought it into sharper focus, particularly seeing where he was murdered.
        </QuoteBox>
        <QuoteBox cite="Visitor">
          The vastness of the building and the beauty of the architecture, along with the rituals of the choristers entering followed by the minister, make [Evensong] an experience on a completely different level to that of attending a normal Church service.
        </QuoteBox>
        <QuoteBox cite="Visitor">
          [I was surprised] how much I enjoyed the service. I used to be a
          practising Christian but lost my faith so I am always surprised when I
          still feel something spiritual… in a place of worship.
        </QuoteBox>
        <QuoteBox cite="Pilgrim">
          I arrived at the Martyrdom on a Friday afternoon as a completion of my pilgrimage and went from there to Evensong: I returned on the Sunday morning (Feast of the Holy Cross) as a liturgical completion of the whole visit to Canterbury.
        </QuoteBox>
        <QuoteBox cite="Pilgrim">
          I lit a candle at the site of the Martyrdom as a mark of having arrived at the end of my pilgrimage; I lit a small number of candles in the crypt (near the site of the original tomb) for people whose concerns and anxieties I had been ‘carrying’ on the walk.
        </QuoteBox>
        <QuoteBox cite="Visitor">
          [Purchasing items in the Cathedral shop] Will definitely make them more special to me because of where I bought them.
        </QuoteBox>
        <QuoteBox cite="Visitor">
          You arrive in the cathedral precinct (grounds) and immediately have a sense that you are perhaps passing from the 21st century back to the Middle Ages… and you know that you’re on a site that’s been sacred since whenever the cathedral was built or before the cathedral was built.
        </QuoteBox>
        <QuoteBox cite="Visitor">
          My impression of this place was – wow! This cathedral is rooted so deep down in the earth. A feeling I have never had in this intensity, or in this depth. And not only rooted in the earth, but in time. As if the Cathedral was here as a… what’s the word?… A witness.
        </QuoteBox>
      </QuotesContainer>

      <DiscussBox>
        Some people call themselves visitors, some pilgrims and some both. Do you think there is a difference?
      </DiscussBox>
      <DiscussBox>
        What is the lasting impact on the pilgrim's life?
      </DiscussBox>

      <ToSeeBox
        items={[
          {
            location: "Bell Harry Tower",
            items: [
              {
                title: "Stop and look up",
                content: "Look up into Bell Harry Tower, the tallest part of this extraordinary building. This tower will be the first glimpse many pilgrims have of the Cathedral as they enter Canterbury."
              }
            ]
          },
          {
            location: "The Cathedral",
            items: [
              {
                title: "Light a candle",
                content: "There are many places inside the Cathedral that offer the opportunity to pause, reflect and light a candle."
              }
            ]
          },
          {
            location: "The Nave",
            items: [
              {
                title: "Sit in a pew",
                content: "Despite the significant number of visitors at some of our busier times, it is nearly always possible to find a quiet spot to sit, look around and listen to the sounds of this sacred place."
              }
            ]
          },
          {
            location: "The Crypt",
            items: [
              {
                title: "Write a prayer",
                content: "Your prayer, added to the prayer board in the crypt, will be placed on the altar during the morning service the following day."
              }
            ]
          },
          {
            location: "The Cathedral",
            items: [
              {
                title: "Join a Service",
                content: "Many services take place every day in Canterbury Cathedral and you are most welcome to attend. Check the Cathedral website for the service that is most convenient for you and come along. If you are hoping to bring a group, please do let us know in advance so that we can reserve seats."
              }
            ]
          }
        ]}
      />

      <h2>Activities</h2>
      <ActivityBox
        title="Pilgrimage activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/Pilgrimage.pdf')}
      >
        Click here to find a programme of curriculum-linked classroom activities and resources to support the ‘Pilgrimage’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "What is pilgrimage?", to: "/pilgrimage/what-is-pilgrimage" },
          {
            name: "Medieval pilgrims",
            to: "/pilgrimage/medieval-pilgrimage",
          },
          {
            name: "Chaucer",
            to: "/pilgrimage/chaucer",
          },
        ]}
      />

      <hr />
      <CopyrightBox />
    </Page>
  </Layout>
)

export default PilgrimageToday;